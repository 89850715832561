<template>
  <div class="flex flex-col gap-5">
    <div class="flex gap-3">
      <multiple-select v-for="staticFilter in SearchStatistic.staticFilter" :filter="staticFilter" class="flex-1"/>

      <VueDatePicker
          class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg w-[30%]"
          v-model="SearchStatistic.dateStart"
          locale="ru"
          auto-apply
          model-type="yyyy-MM-dd"
          :format="'dd.MM.yyyy'"
          placeholder="Дата начала"
          :enable-time-picker="false">
      </VueDatePicker>

      <VueDatePicker
          class="[&_input]:text-gray-900 [&_input]:text-sm [&_input]:py-2.5 [&_input]:bg-gray-50 [&_input]:border-gray-300 [&_input]:border [&_input]:rounded-lg w-[30%]"
          v-model="SearchStatistic.dateEnd"
          locale="ru"
          auto-apply
          model-type="yyyy-MM-dd"
          :format="'dd.MM.yyyy'"
          placeholder="Дата конца"
          :enable-time-picker="false">
      </VueDatePicker>
    </div>
    <div class="flex gap-3">
      <div class="flex gap-3 w-[80%]" v-if="SearchStatistic?.reactiveFilter && SearchStatistic?.reactiveFilter.length">
        <single-select v-model="SearchStatistic.type" title="Тип запроса" :elLists="types" class="w-[20%]"/>
        <multiple-select v-for="filter in SearchStatistic.reactiveFilter" :filter="filter" class="flex-1"/>
      </div>

      <button class="p-2 text-sm font-medium rounded-lg text-blue-700 ring-1 border-blue-700 hover:bg-gray-100 w-fit" @click="SearchStatistic.applyParamFilter">Применить</button>
      <button class="p-2 text-sm font-medium w-fit text-gray-500" @click="resetFilter">Сбросить фильтры</button>
    </div>

  </div>
</template>

<script setup>
import MultipleSelect from "./filter/multipleSelect.vue";
import {SearchStatisticStore} from "../../stores/SearchStatisticStore.js";
import {useRoute} from "vue-router";
import SingleSelect from "../coverage/filter/singleSelect.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import {ref, watch} from "vue";

const SearchStatistic = SearchStatisticStore()

const route = useRoute()

const type = ref()

const resetFilter = () => {
  type.value = null
  SearchStatistic.resetFilter()
}

watch(SearchStatistic, () => {
  if (type.value != SearchStatistic.type && SearchStatistic.type !== null) {
    type.value = SearchStatistic.type
    SearchStatistic.getDefaultFilters(false)
  }
})

const types = [
  {
    id: 'elastic',
    title: "Elastic Search",
  },
  {
    id: 'stepbystep',
    title: "StepByStep",
  }
]
</script>