<template>
 <div class="px-8 py-6">
  <div class="bg-gray-50 border border-gray-2 rounded-lg min-h-[600px]">
   <h1 class="p-8 border-b border-gray-2 text-3xl font-medium">Статистика поиска</h1>
   <div class="pt-6 px-8 py-24">
    <statistic-filter/>
    <statistic class="mt-12"/>
   </div>
  </div>
 </div>
</template>

<script setup>
import statisticFilter from "../searchStatistic/filter.vue";
import Statistic from "../searchStatistic/filter/statistic.vue";
import {onMounted} from "vue";
import {useRoute} from "vue-router";
import {SearchStatisticStore} from "../../stores/SearchStatisticStore.js";

const route = useRoute()
const statisticStore = SearchStatisticStore()

onMounted(() => {
  statisticStore.getDefaultFilters(route.query)
})
</script>
