<template>
  <div class="min-h-[430px] bg-white rounded-lg shadow-[0px_1px_8px_0px_rgba(0,0,0,0.12)]">
    <graph-head>
      <span>
        <span class="text-blue-600">Общие</span> данные
      </span>
    </graph-head>

    <div class="p-6">
      <div id="donut-chart"></div>

      <div v-if="SearchStatistic?.infoFilter && SearchStatistic.infoFilter.length" class="flex flex-col gap-3">
        <div v-for="item in SearchStatistic.infoFilter" class="flex justify-between text-sm cursor-pointer"
             @click="addParamFilter(item)">
          <div class="flex items-center gap-2">
            <div v-if="item.color" class="w-2 h-2 rounded-full" :style="`background: ${item.color}`"></div>
            <div v-if="item.name" class="font-medium">{{ item.name }}</div>
          </div>
          <div class="text-gray-400">{{ item.percentage }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GraphHead from "./components/graphHead.vue";
import ApexCharts from 'apexcharts'
import {computed, onMounted, ref, watch} from "vue";
import {SearchStatisticStore} from "../../../stores/SearchStatisticStore";

const chart = ref()
const SearchStatistic = SearchStatisticStore()
const isUpdate = computed(() => SearchStatistic.isLoaded);

const addParamFilter = (item) => {
  let tmpItem = Object.keys(SearchStatistic.activeParamFilter).find(el => el === item.type)
  if (tmpItem) {
    let tmpArr = SearchStatistic.activeParamFilter[tmpItem]
    let duplicate = tmpArr.find(dup => dup === item.name)
    if (!duplicate) {
      tmpArr.push(item.name)
      SearchStatistic.addParamReactiveFilter(item.type, tmpArr)
    }
  } else SearchStatistic.addParamReactiveFilter(item.type, [item.name])
}

//Инициализация графика
const initChart = () => {
  chart.value = new ApexCharts(document.getElementById("donut-chart"), getChartOptions([], 0));
  chart.value.render();
}

//Обновление данных в графике
const updateChart = (data = [], total = 0) => {
  chart.value.updateOptions(getChartOptions(data, total))
}

//Получение опция для графика
const getChartOptions = (data, total) => {
  return {
    series: getCurrentArr('count', data),
    colors: getCurrentArr('color', data),
    chart: {
      height: 320,
      width: "100%",
      type: "donut",
    },
    stroke: {
      colors: ["transparent"],
      lineCap: "",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontFamily: "Inter, sans-serif",
              offsetY: 20,
            },
            total: {
              showAlways: true,
              show: true,
              label: "Успешно",
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: "Inter, sans-serif",
              formatter: function (w) {
                return `${total}%`
              },
            },
            value: {
              show: true,
              fontSize: '28px',
              fontWeight: 'bold',
              fontFamily: "Inter, sans-serif",
              offsetY: -20,
              formatter: function (value) {
                return value
              },
            },
          },
          size: "80%",
        },
      },
    },
    labels: getCurrentArr('name', data),
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      fontFamily: "Inter, sans-serif",
    },
  }
}

const getCurrentArr = (key, data) => {
  if (data.length) return data.map(el => el[key])
  else return []
}

watch(isUpdate, () => {
  if (isUpdate.value) {
    updateChart(SearchStatistic.infoFilter, SearchStatistic.total)
  }
})

onMounted(() => {
  initChart()
})
</script>